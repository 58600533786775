<template>
  <div>
    <v-card>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar dense class="tc-title">
          <v-toolbar-title>Feedback Report</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <br>
        <div>
            <v-row justify="end" class="mr-5">
                <v-btn v-if="feedback_report_list.length != 0" @click="exportexcel()" color="success" dark class="mb-2">
                    <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
            </v-row>
            <v-simple-table id="exceltable" class="elevation-1" :search="search" v-if="feedback_report_list.length!=0">
                <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left"> Feedback Type </th>
                        <th class="text-left"> Academic Year</th>
                        <th class="text-left"> Semester</th>
                        <th class="text-left"> Feedback Number</th>
                        <th class="text-left"> Start Date</th>
                        <th class="text-left"> End Date</th>
                        <th class="text-left"> #Responses</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in feedback_report_list" :key="item.srno">
                            <td>{{ item.name }}</td>
                            <td>{{ item.ay }}</td>
                            <td>{{ item.sem }}</td>
                            <td>{{ item.feedback_number }}</td>
                            <td>{{ item.start_date }}</td>
                            <td>{{ item.end_date }}</td>
                            <td>
                                <v-toolbar-title style="color:blue;font-size:16px;" v-if="item.response!='--'">
                                    <router-link :to="{ name: 'feedback-report-details', params: {feedback_count_id:item.feedback_count_id,feedback_type_id:item.feedback_type_id},}">{{ item.response }}</router-link>
                                </v-toolbar-title>
                                <span v-else>{{ item.response }}</span>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-card>
    <br />
  </div>
</template>

<script>
import axios from "axios";
import {
    table2excel
} from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
    data: () => ({
        overlay: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",

        feedback_report_list: [],

    }),

    mounted() {
        axios
        .post("/Admin/fetchFeedbackReport")
        .then(res => {
            if (res.data.msg == "200") {
                this.feedback_report_list = res.data.feedback_report_list;
            }
        });
    },

    methods: {
        exportexcel() {
            $("#exceltable").table2excel({
                name: "Worksheet Name",
                filename: "Feedback_Report", //do not include extension
                fileext: ".xls" // file extension
            });
        },//end of function

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },//end of function

        clear() {
            this.excelfile = null;
            this.exceldialog = false;
        },//end of function

        closeerrordialog() {
            this.clear();
            this.excelerrordialog = false;
        },//end of function
    }
};
</script>

<style scoped>
.required {
    color: red;
    font-size: 14px;
    font-style: bold;
}

.tc-title {
    background-image: linear-gradient(-90deg, #fff, #057996);
    color: #fff;
    border-radius: 3px;
}
</style>
